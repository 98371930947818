<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title>Tabellen</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ion-grid>
                <ion-row class="ion-align-items-center">
                    <ion-col size="12">
                        <h4>Wähle Deinen Verein</h4>
                    </ion-col>
                    <ion-col size="12">
                        <ion-row>
                            <ion-col size="12" size-sm="6">
                                <ion-item>
                                    <ion-select placeholder="Bitte Verein wählen">
                                        <ion-select-option value="clubId1">ALBA Berlin</ion-select-option>
                                        <ion-select-option value="clubId2">RedHawks Potsdam</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                            <ion-col size="12" size-sm="6">
                                <ion-item>
                                    <ion-select placeholder="Bitte Team wählen">
                                        <ion-select-option value="mU18">mU18</ion-select-option>
                                        <ion-select-option value="mU16">mU16</ion-select-option>
                                        <ion-select-option value="mU14">mU14</ion-select-option>
                                        <ion-select-option value="xU12">xU12</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <iframe
                id="iframe_widget_id_oh_hr_aktuell"
                src="https://www.basketball-bund.net/widget/widgets/index2.html#!/ligawidget/liga/1401/params/%7B%22ligaNr%22:%221401%22,%22iframeWidth%22:400,%22iframeHeight%22:800,%22rangeDays%22:8,%22activeTap%22:%22aktuell%22,%22titleBgColor%22:%226115ad%22,%22tapBorderColorActive%22:%226115ad%22,%22showRefreshButton%22:true%7D"
                style="border-width: 0"
                width="100%"
                height="100%"
            ></iframe>

            <iframe
                id="iframe_widget_1590254310916"
                src="https://www.basketball-bund.net/widget/widgets/index2.html#!/vereinswidget/verein/2177/params/%7B%22iframeWidth%22:770,%22iframeHeight%22:700,%22showRefreshButton%22:true,%22titleColor%22:%22FFFFFF%22,%22titleBgColor%22:%22E94C16%22,%22tapColor%22:%22FFFFFF%22,%22tapBgColor%22:%22666666%22,%22colorMatchGroup%22:%22333333%22,%22bgColorMatchGroup%22:%22DDDDDD%22,%22colorMatchListItem%22:%22000000%22,%22bgColorMatchListItem%22:%22FFFFFF%22,%22vereinsId%22:%222177%22,%22homeMatchesOnlyInit%22:false,%22rangeDays%22:7%7D"
                style="border-width: 0"
                width="100%"
                height="100%"
            ></iframe>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonButtons,
    IonContent,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";

import { onBeforeMount, onMounted } from "@vue/runtime-core";

import { defineComponent } from 'vue';
export default defineComponent({
    name: "Folder",
    data: function() {
        return {};
    },
    components: {
        IonButtons,
        IonContent,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
    },
    methods: {},
    setup() {
        onBeforeMount(() => {
            console.log("Before mount!");

            /*             const mywidget = new widget();
                        mywidget.ligawidget("widget_id_oh_hr_aktuell", {
                            ligaNr: "1401",
                            iframeWidth: 400,
                            iframeHeight: 800,
                            rangeDays: 8,
                            activeTap: "aktuell",
                            titleBgColor: "9C2424",
                            tapBorderColorActive: "F5821F",
                            showRefreshButton: true,
                        }); */
        });
        onMounted(() => {
            console.log("Mounted!");

            /*             widget.ligawidget('widget_id_oh_hr_aktuell', {
                "ligaNr":"1401", 
                "iframeWidth":400, 
                "iframeHeight":800, 
                "rangeDays":8, 
                "activeTap":"aktuell", 
                "titleBgColor":"9C2424", 
                "tapBorderColorActive":"F5821F", 
                "showRefreshButton":true, 
            }); */
        });
        return {};
    },
});
</script>

<style scoped>
ion-select {
    width: 100%;
}
</style>