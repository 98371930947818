
import {
    IonButtons,
    IonContent,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";

import { onBeforeMount, onMounted } from "@vue/runtime-core";

import { defineComponent } from 'vue';
export default defineComponent({
    name: "Folder",
    data: function() {
        return {};
    },
    components: {
        IonButtons,
        IonContent,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
    },
    methods: {},
    setup() {
        onBeforeMount(() => {
            console.log("Before mount!");

            /*             const mywidget = new widget();
                        mywidget.ligawidget("widget_id_oh_hr_aktuell", {
                            ligaNr: "1401",
                            iframeWidth: 400,
                            iframeHeight: 800,
                            rangeDays: 8,
                            activeTap: "aktuell",
                            titleBgColor: "9C2424",
                            tapBorderColorActive: "F5821F",
                            showRefreshButton: true,
                        }); */
        });
        onMounted(() => {
            console.log("Mounted!");

            /*             widget.ligawidget('widget_id_oh_hr_aktuell', {
                "ligaNr":"1401", 
                "iframeWidth":400, 
                "iframeHeight":800, 
                "rangeDays":8, 
                "activeTap":"aktuell", 
                "titleBgColor":"9C2424", 
                "tapBorderColorActive":"F5821F", 
                "showRefreshButton":true, 
            }); */
        });
        return {};
    },
});
